<script setup lang="ts">
import CloseIcon from '../icons/CloseIcon.vue';

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  useBackground: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['close']);
</script>

<template>
  <transition
    appear
    enter-active-class="transition-opacity ease-out"
    leave-active-class="transition-opacity ease-in"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="props.showModal"
      class="fixed top-0 left-0 z-50 flex items justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur"
      @click.self="() => $emit('close')"
    >
      <div
        class="w-full h-full flex justify-center items-center"
        @click.self="$emit('close')"
      >
        <div
          v-if="props.useBackground"
          class="absolute md:w-full w-[95%] max-w-4xl rounded shadow"
        >
          <div class="bg-dark-widget w-full">
            <div class="w-full flex justify-end pt-5 pr-5">
              <CloseIcon
                class="cursor-pointer"
                @click="$emit('close')"
              />
            </div>
            <div class="px-10 pb-10 w-full">
              <slot />
            </div>
          </div>
        </div>
        <template v-else>
          <slot />
        </template>
      </div>
    </div>
  </transition>
</template>
